import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
//import authcheck from '@/classes/authcheck'
//import MainpageLayout from '@/layouts/MainpageLayout.vue'
//import SubpageLayout from '@/layouts/SubpageLayout.vue'

const routes = [
  /*{
    path: '/',
    name: 'Main',
    component: () => import('@/views/main/index.vue'),
    meta: { layout: MainpageLayout }
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: () => import('@/views/main/About'),
    meta: { layout: SubpageLayout, title: '사업안내'}
  },
  {
    path: '/graduate',
    name: 'GraduatePage',
    component: () => import('@/views/main/Graduate'),
    meta: { layout: SubpageLayout, title: '운영대학원'}
  },
  {
    path: '/notice',
    children: [
      {
        path: '/notice/list',
        name: 'NoticeListPage',
        component: () => import('@/views/main/Notice/NoticeList.vue'),
        meta: { layout: SubpageLayout, title: '공지사항'}
      },
      {
        path: '/notice/write',
        name: 'NoticeWritePage',
        component: () => import('@/views/main/Notice/NoticeWrite.vue'),
        meta: { layout: SubpageLayout, title: '공지사항'}
      },
      {
        path: '/notice/detail',
        name: 'NoticeViewPage',
        component: () => import('@/views/main/Notice/NoticeView.vue'),
        meta: { layout: SubpageLayout, title: '공지사항'}
      },
    ]
  },
  {
    path: '/schedule',
    children: [
      {
        path: '/schedule/list',
        name: 'ScheduleListPage',
        component: () => import('@/views/main/Schedule/ScheduleList.vue'),
        meta: { layout: SubpageLayout, title: '일정안내'}
      },
      {
        path: '/schedule/write',
        name: 'ScheduleWritePage',
        component: () => import('@/views/main/Schedule/ScheduleWrite.vue'),
        meta: { layout: SubpageLayout, title: '일정안내'}
      },
      {
        path: '/schedule/detail',
        name: 'ScheduleViewPage',
        component: () => import('@/views/main/Schedule/ScheduleView.vue'),
        meta: { layout: SubpageLayout, title: '일정안내'}
      },
    ]
  },
  {
    path: '/info',
    children: [
      {
        path: '/info/list',
        name: 'InfoListPage',
        component: () => import('@/views/main/Info/InfoList.vue'),
        meta: { layout: SubpageLayout, title: '자료실', target: 'info' }
      },
      {
        path: '/info/write',
        name: 'InfoWritePage',
        component: () => import('@/views/main/Info/InfoWrite.vue'),
        meta: { layout: SubpageLayout, title: '자료실', target: 'info' }
      },
      {
        path: '/info/detail',
        name: 'InfoViewPage',
        component: () => import('@/views/main/Info/InfoView.vue'),
        meta: { layout: SubpageLayout, title: '자료실'}
      },
    ]
  },*/
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/total',
    children: [
      {
        path: '/total',
        name: 'total',
        component: () => import('@/views/wesgs/total.vue'),
      },
      {
        path: '/education',
        name: 'education',
        component: () => import('@/views/wesgs/education.vue'),
      },
      {
        path: '/course',
        name: 'course',
        component: () => import('@/views/wesgs/course.vue'),
      },
      {
        path: '/textbook',
        name: 'textbook',
        component: () => import('@/views/wesgs/textbook.vue'),
      },
      {
        path: '/lecture',
        name: 'lecture',
        component: () => import('@/views/wesgs/lecture.vue'),
      },
      {
        path: '/internship',
        name: 'internship',
        component: () => import('@/views/wesgs/internship.vue'),
      },
      {
        path: '/research',
        name: 'research',
        component: () => import('@/views/wesgs/research.vue'),
      },
      {
        path: '/practice',
        name: 'practice',
        component: () => import('@/views/wesgs/practice.vue'),
      },
      {
        path: '/thesis',
        name: 'thesis',
        component: () => import('@/views/wesgs/thesis.vue'),
      },
      {
        path: '/conference',
        name: 'conference',
        component: () => import('@/views/wesgs/conference.vue'),
      },
      {
        path: '/project',
        name: 'project',
        component: () => import('@/views/wesgs/project.vue'),
      },
      {
        path: '/result',
        name: 'result',
        component: () => import('@/views/wesgs/result.vue'),
      },
      {
        path: '/userinfo',
        name: 'userinfo',
        component: () => import('@/views/wesgs/userinfo.vue'),
      },
      {
        path: '/scholarinfo',
        name: 'scholarinfo',
        component: () => import('@/views/wesgs/scholarinfo.vue'),
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      /*{
        path: '/theme',
        name: 'Theme',
        redirect: '/theme/typography',
      },
      {
        path: '/theme/colors',
        name: 'Colors',
        component: () => import('@/views/theme/Colors.vue'),
      },
      {
        path: '/theme/typography',
        name: 'Typography',
        component: () => import('@/views/theme/Typography.vue'),
      },
      {
        path: '/base',
        name: 'Base',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/base/breadcrumbs',
        children: [
          {
            path: '/base/accordion',
            name: 'Accordion',
            component: () => import('@/views/base/Accordion.vue'),
          },
          {
            path: '/base/breadcrumbs',
            name: 'Breadcrumbs',
            component: () => import('@/views/base/Breadcrumbs.vue'),
          },
          {
            path: '/base/cards',
            name: 'Cards',
            component: () => import('@/views/base/Cards.vue'),
          },
          {
            path: '/base/carousels',
            name: 'Carousels',
            component: () => import('@/views/base/Carousels.vue'),
          },
          {
            path: '/base/collapses',
            name: 'Collapses',
            component: () => import('@/views/base/Collapses.vue'),
          },
          {
            path: '/base/list-groups',
            name: 'List Groups',
            component: () => import('@/views/base/ListGroups.vue'),
          },
          {
            path: '/base/navs',
            name: 'Navs',
            component: () => import('@/views/base/Navs.vue'),
          },
          {
            path: '/base/paginations',
            name: 'Paginations',
            component: () => import('@/views/base/Paginations.vue'),
          },
          {
            path: '/base/placeholders',
            name: 'Placeholders',
            component: () => import('@/views/base/Placeholders.vue'),
          },
          {
            path: '/base/popovers',
            name: 'Popovers',
            component: () => import('@/views/base/Popovers.vue'),
          },
          {
            path: '/base/progress',
            name: 'Progress',
            component: () => import('@/views/base/Progress.vue'),
          },
          {
            path: '/base/spinners',
            name: 'Spinners',
            component: () => import('@/views/base/Spinners.vue'),
          },
          {
            path: '/base/tables',
            name: 'Tables',
            component: () => import('@/views/base/Tables.vue'),
          },
          {
            path: '/base/tooltips',
            name: 'Tooltips',
            component: () => import('@/views/base/Tooltips.vue'),
          },
        ],
      },
      {
        path: '/buttons',
        name: 'Buttons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/buttons/standard-buttons',
        children: [
          {
            path: '/buttons/standard-buttons',
            name: 'Buttons',
            component: () => import('@/views/buttons/Buttons.vue'),
          },
          {
            path: '/buttons/dropdowns',
            name: 'Dropdowns',
            component: () => import('@/views/buttons/Dropdowns.vue'),
          },
          {
            path: '/buttons/button-groups',
            name: 'Button Groups',
            component: () => import('@/views/buttons/ButtonGroups.vue'),
          },
        ],
      },
      {
        path: '/forms',
        name: 'Forms',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/forms/form-control',
        children: [
          {
            path: '/forms/form-control',
            name: 'Form Control',
            component: () => import('@/views/forms/FormControl.vue'),
          },
          {
            path: '/forms/select',
            name: 'Select',
            component: () => import('@/views/forms/Select.vue'),
          },
          {
            path: '/forms/checks-radios',
            name: 'Checks & Radios',
            component: () => import('@/views/forms/ChecksRadios.vue'),
          },
          {
            path: '/forms/range',
            name: 'Range',
            component: () => import('@/views/forms/Range.vue'),
          },
          {
            path: '/forms/input-group',
            name: 'Input Group',
            component: () => import('@/views/forms/InputGroup.vue'),
          },
          {
            path: '/forms/floating-labels',
            name: 'Floating Labels',
            component: () => import('@/views/forms/FloatingLabels.vue'),
          },
          {
            path: '/forms/layout',
            name: 'Layout',
            component: () => import('@/views/forms/Layout.vue'),
          },
          {
            path: '/forms/validation',
            name: 'Validation',
            component: () => import('@/views/forms/Validation.vue'),
          },
        ],
      },
      {
        path: '/charts',
        name: 'Charts',
        component: () => import('@/views/charts/Charts.vue'),
      },
      {
        path: '/icons',
        name: 'Icons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/icons/coreui-icons',
        children: [
          {
            path: '/icons/coreui-icons',
            name: 'CoreUI Icons',
            component: () => import('@/views/icons/CoreUIIcons.vue'),
          },
          {
            path: '/icons/brands',
            name: 'Brands',
            component: () => import('@/views/icons/Brands.vue'),
          },
          {
            path: '/icons/flags',
            name: 'Flags',
            component: () => import('@/views/icons/Flags.vue'),
          },
        ],
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/notifications/alerts',
        children: [
          {
            path: '/notifications/alerts',
            name: 'Alerts',
            component: () => import('@/views/notifications/Alerts.vue'),
          },
          {
            path: '/notifications/badges',
            name: 'Badges',
            component: () => import('@/views/notifications/Badges.vue'),
          },
          {
            path: '/notifications/modals',
            name: 'Modals',
            component: () => import('@/views/notifications/Modals.vue'),
          },
        ],
      },
      {
        path: '/widgets',
        name: 'Widgets',
        component: () => import('@/views/widgets/Widgets.vue'),
      },*/
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

/*router.beforeEach((to, from, next) => {
  if(!authcheck() && to.name != 'login') {
    next({ name: 'login' });
  } else {
    next();
  }
})*/

export default router
